<template>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      background-color: rgba(33, 34, 68, 1);
    "
  >
    <van-row>
      <van-col span="24">
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img
              :src="item.picUrl"
              alt=""
              style="width: 100%; height: 100%; border-radius: 20px"
            />
          </van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>
    <van-row style="margin-top: 20px">
      <van-col span="24" style="font-size: 1.5rem; font-weight: 500">
        UTMS {{ $t("xinyidai") }}
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list1.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("wuxuzhuanyi")
          }}</span>
        </div>
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list2.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("shouruwending")
          }}</span>
        </div>
      </van-col>
      <van-col span="24" style="margin-top: 10px">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          "
        >
          <img
            :src="require('@/assets/image/list3.png')"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span style="margin-left: 20px; box-sizing: border-box">{{
            $t("mianfeituichu")
          }}</span>
        </div>
      </van-col>
      <van-col
        span="24"
        style="margin-top: 20px; color: rgba(112, 128, 179, 1)"
      >
        {{ $t("zhiliyudazao") }} <span style="color: #ffffff">DEX,IMO</span>and
        <span style="color: #ffffff">DAO</span>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("xiangmutese") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row1.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("anquanyoukekao") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("wuxuzhuanbi") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row2.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("zhuanyewendingxing") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("zhuanyetuandui") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" style="margin-top: 20px">
        <van-row>
          <van-col span="5">
            <img
              :src="require('@/assets/image/row3.png')"
              alt=""
              style="width: 51px; height: 51px"
            />
          </van-col>
          <van-col span="19">
            <van-row align="center">
              <van-col span="24" style="font-size: 16px">
                {{ $t("jinrumenkandi") }}
              </van-col>
              <van-col
                span="24"
                style="color: #7080b3; margin-top: 10px; box-sizing: border-box"
              >
                {{ $t("fenxiangjiedianwakuang") }}
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("fenjishouru") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px; color: #7080b3">
        <van-row>
          <van-col span="4">
            {{ $t("dengji") }}
          </van-col>
          <van-col span="16" style="text-align: center"> USDT </van-col>
          <van-col span="4" style="text-align: right">
            {{ $t("shouyi") }}(%)
          </van-col>
        </van-row>
        <van-row
          style="margin-top: 10px"
          v-for="(item, index) in levelList"
          :key="index"
        >
          <van-col span="4">
            {{ item.name }}
          </van-col>
          <van-col span="16" style="text-align: center">
            {{ item.minMoney }} - {{ item.maxMoney }}
          </van-col>
          <van-col span="4" style="text-align: right">
            {{ item.rewardRate * 100 }}%
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: #212244;
      margin-top: 20px;
    "
  >
    <van-row>
      <van-col span="24" style="font-size: 1.5rem">
        {{ $t("hezuo") }}
      </van-col>
      <van-col span="24" style="margin-top: 20px; color: #7080b3">
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/biance.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Binance</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/ftx.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">FTX</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/kucoin.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">kuCoin</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/kraken.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">KraKen</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/gate.io.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Gate.io</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/uopbit.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Upbit</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/okex.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">OkEx</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/bitfinex.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Bitfinex</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/wazirx.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">Wazirx</span>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top: 10px">
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/list1.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">huobi global</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/crypto.com.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">crypto.com</span>
            </div>
          </van-col>
          <van-col span="8">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 20px;
                box-sizing: border-box;
                background-color: rgba(44, 44, 83, 1);
                border-radius: 10px;
              "
            >
              <img
                :src="require('@/assets/image/coinbase.png')"
                alt=""
                style="width: 20px; height: 20px"
              />
              <span style="margin-left: 10px">coinBase</span>
            </div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <div
    style="
      width: 100%;
      margin-top: 20px;
      text-align: center;
      font-size: 0.875rem;
    "
  >
    © 2022 All rights reserved
  </div>
  <div style="height: 90px"></div>
</template>

<script>
export default {
  name: "service",
  data() {
    return {
      bannerList: [],
      levelList: [],
    };
  },
  mounted() {
    this.getBanner();
    this.getLevel();
  },
  methods: {
    getBanner() {
      this.$request.get("/nft/scBanner/list", "").then((res) => {
        console.log(res.data.code);
        if (res.data.code == 200) {
          this.bannerList = res.data.result.records;
        }
      });
    },
    getLevel() {
      let params = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$request.get("/nft/scLevel/list", params).then((res) => {
        if (res.data.code === 200) {
          this.levelList = res.data.result.records;
          this.levelList.reverse()
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
